/* eslint-disable no-multi-assign */
import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import Info from 'components/elements/SvgElements/Info'
import { useMedia } from 'react-use'

interface CalendarProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Calendar
}

const Wrapper = styled.div`
  position: relative;
  z-index: 50;
  background-color: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 30%);
  max-width: 1600px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    & .container {
      max-width: 1400px !important;
    }
  }

  @media (min-width: 992px) {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 991.98px) {
    padding: 2rem 0;

    .container {
      max-width: 100%;
      padding: 0 !important;
    }
  }
`

const Switch = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  background: linear-gradient(149.19deg, #eb0045 -10.56%, #ff8300 62.96%);
  padding: 0.3rem 0.5rem;
  margin: 0 auto;
`

const Hourtype = styled.div<{ active?: boolean }>`
  border-radius: 9999px;

  ${(props) =>
    props.active &&
    css`
      background-color: #fff;
      color: #ff8300;
    `}

  padding: 0.75rem;
`

const Content = styled(ParseContent)`
  & p {
    line-height: 1.2;
  }
`

const Grid = styled.div<{ rows: number }>`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: repeat(${(props) => props.rows}, minmax(0, 1fr));
  width: 100%;
  color: #676767;
  height: 1080px;

  @media (max-width: 991px) {
    width: auto;
  }
`

const YCol = styled.div`
  position: relative;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row: span 2 / span 2;
  text-align: right;

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    height: 1px;
    width: 520%;
    background: #e8e8e8;
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    height: 1px;
    width: 520%;
    background: #e8e8e8;
  }

  @media (min-width: 992px) {
    padding: 0 0.75rem;
  }

  @media (max-width: 991.98px) {
    font-size: 10px;
  }
`

const GridCol = styled.div<{ col: number; rows: number }>`
  grid-column-start: ${(props) => props.col};
  grid-column-end: ${(props) => props.col};
  grid-row-start: 1;
  grid-row-end: ${(props) => props.rows};
  max-width: 100%;

  &:after {
    position: absolute;
    content: '';
    right: 0;
    height: 110%;
    top: -5%;
    width: 1px;
    background: #e8e8e8;
  }
  position: relative;

  @media (min-width: 992px) {
    min-width: 150px;
  }
`

const InnerGrid = styled.div<{ rows: number }>`
  display: grid;
  grid-auto-rows: 1.5px;
`

const RowSpan = styled.div<{ rowStart: number; rowEnd: number }>`
  & * {
    font-size: 12px;
    line-height: 18px;
  }
  grid-row-start: ${(props) => props.rowStart};
  grid-row-end: ${(props) => props.rowEnd};

  @media (max-width: 991.98px) {
    max-width: 100%;

    > div {
      max-width: 100%;
    }

    & * {
      font-size: 10px;
      line-height: 16px;
    }
  }
  position: relative;
`

const InnerRow = styled.div<{ bg?: string }>`
  border-radius: 8px;
  padding: 0.125rem;
  ${(props) =>
    props.bg === 'blue' &&
    css`
      background-color: #efeffd;
      border-left: 2.5px solid #5d5fef;
    `}

  ${(props) =>
    props.bg === 'orange' &&
    css`
      background-color: #fff3e5;
      border-left: 2.5px solid #ff8300; ;
    `}

  ${(props) =>
    props.bg === 'transparent' &&
    css`
      background-color: transparent;
      border-left: none;
    `}
    height: 100%;

  @media (max-width: 991.98px) {
    max-width: 100% !important;
    padding: 4px;
    display: flex;
    word-break: break-all;
    overflow: hidden !important;
  }
`

const DayLabels = styled.div`
  position: relative;
  opacity: 0.75;
  display: grid;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  width: 100%;
  grid-template-columns: repeat(6, minmax(0, 1fr));

  @media (max-width: 991px) {
    > div {
      font-size: 12px;
      width: 100%;
    }
  }

  &:after {
    position: absolute;
    content: '';
    background: #e8e8e8;
    bottom: -26%;
    width: 86%;
    height: 1px;
    left: 16%;
  }
`

const PopupInfo = styled(motion.div)<{ bg: string; day?: string }>`
  position: absolute;
  bottom: 50%;
  right: -80%;
  z-index: 99;
  border-radius: 12px;
  font-size: 12px;

  padding-left: 0.75rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  ${(props) =>
    props.bg === 'blue' &&
    css`
      background-color: #efeffd;
      border: 1.5px solid #5d5fef;
    `}
  ${(props) =>
    props.bg === 'orange' &&
    css`
      background-color: #fff3e5;
      border: 1.5px solid #ff8300; ;
    `};

  @media (min-width: 992px) {
    ${(props) =>
      props.day === 'friday' &&
      css`
        right: -5% !important;
      `}
    width: 200%;
    pointer-events: none;
    & svg {
      display: none !important;
    }
  }

  @media (max-width: 991.98px) {
    position: fixed;
    width: 95%;
    margin: 0 auto;
    bottom: 10%;
    right: 2.5%;
    overflow: hidden;
    height: fit-content;
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.5);
    pointer-events: auto;
    & * {
      font-size: 12px !important;
    }
  }
`

const InfoWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: 4px;

  @media (max-width: 991px) {
    bottom: 1px;
    right: 1px;
    > svg {
      width: 9px;
      height: 9px;
    }
  }
`

const GridWrapper = styled.div`
  @media (min-width: 992px) {
    margin-left: -10rem;
    min-width: 886px;
  }
  @media (max-width: 991.98px) {
    overflow: hidden;
    scroll-snap-type: x proximity;
    max-width: 100%;
  }
`

const Calendar: React.FC<CalendarProps> = ({ fields }) => {
  const isMobile = useMedia('(max-width: 991px)')

  const [currentHourtype, setCurrentHourtype] = useState(0)
  const [showPopupInfo, setShowPopupInfo] = useState<string>('')

  const currentAgenda = fields.hourtypes![currentHourtype]

  const starttimeNumber = Number(fields.starttime?.split(':')[0])
  const endtimeNumber = Number(fields.endtime?.split(':')[0])

  const totalHours = endtimeNumber - starttimeNumber
  const totalMinutes = totalHours * 60 + 60

  const yAxis = []
  for (let i = starttimeNumber; i <= endtimeNumber; i += 2) {
    yAxis.push(i)
  }

  let mondayPointer = 1
  let tuesdayPointer = 1
  let wednesdayPointer = 1
  let thursdayPointer = 1
  let fridayPointer = 1

  return (
    <section className="mb-0">
      <Wrapper>
        <div className="container d-flex flex-column align-items-start align-items-lg-center">
          <>
            <h2 className="text-center mb-4 w-100">
              Zo ziet jouw {currentAgenda?.titlealt} werkweek eruit
            </h2>
            <Switch>
              {fields.hourtypes?.map((hourtype, hourTypeIndex) => {
                const active = currentHourtype === hourTypeIndex

                return (
                  <Hourtype
                    role="button"
                    key={hourtype?.title}
                    active={active}
                    onClick={() => setCurrentHourtype(hourTypeIndex)}
                  >
                    <Content content={hourtype?.title} />
                  </Hourtype>
                )
              })}
            </Switch>
            <GridWrapper>
              <DayLabels>
                <div />
                <div className="pl-2">{isMobile ? 'ma' : 'Maandag'}</div>
                <div className="pl-2">{isMobile ? 'di' : 'Dinsdag'}</div>
                <div className="pl-2">{isMobile ? 'wo' : 'Woensdag'}</div>
                <div className="pl-2">{isMobile ? 'do' : 'Donderdag'}</div>
                <div className="pl-2">{isMobile ? 'vr' : 'Vrijdag'}</div>
              </DayLabels>
              <div className="d-flex">
                <Grid rows={yAxis.length * 2}>
                  {yAxis.map((time, i) => (
                    <YCol>
                      <div
                        key={time}
                        className="mb-5 pb-1 pr-3"
                        style={
                          i === 0
                            ? { marginTop: '-1rem' }
                            : { marginTop: '-1rem' }
                        }
                      >
                        <span>{`${time}:00`}</span>
                      </div>
                    </YCol>
                  ))}

                  <GridCol col={2} rows={totalMinutes}>
                    <InnerGrid rows={totalMinutes}>
                      {currentAgenda?.monday?.map((edge, mondayIndex) => {
                        const endDate = new Date(
                          `October 13, 2014 ${edge?.endtime}`
                        )
                        const startDate = new Date(
                          `October 13, 2014 ${edge?.starttime}`
                        )

                        const difference =
                          (endDate.getTime() - startDate.getTime()) /
                          1000 /
                          (60 * 60)

                        const diff = Math.abs(difference)

                        const spanLengthMinutes = diff * 60

                        let rowStart = mondayPointer

                        if (mondayPointer !== 1) {
                          rowStart = mondayPointer
                        }

                        const rowEnd =
                          Math.floor((mondayPointer += spanLengthMinutes)) - 1

                        return (
                          <RowSpan
                            rowStart={rowStart}
                            rowEnd={rowEnd}
                            onMouseEnter={() => {
                              setShowPopupInfo(`monday_${mondayIndex}`)
                            }}
                            onClick={() => {
                              if (showPopupInfo !== '') {
                                return
                              }
                              setShowPopupInfo(`monday_${mondayIndex}`)
                            }}
                            onMouseLeave={() => {
                              setShowPopupInfo('')
                            }}
                          >
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{
                                height: '100%',
                                opacity: 1,
                              }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{
                                delay: mondayIndex * 0.25,
                              }}
                              style={{ maxWidth: '100%' }}
                            >
                              <InnerRow bg={edge?.color || 'blue'}>
                                {edge?.title}
                              </InnerRow>
                            </motion.div>
                            {showPopupInfo === `monday_${mondayIndex}` &&
                              edge?.title && (
                                <PopupInfo
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  bg={edge?.color || 'blue'}
                                  day="monday"
                                >
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <span
                                      style={{ fontWeight: 'bold' }}
                                      className="w-75 pr-4"
                                    >
                                      {edge?.title}
                                    </span>
                                    <span
                                      style={{ fontSize: 11 }}
                                      className="w-25"
                                    >
                                      {edge?.starttime} - {edge?.endtime}
                                    </span>
                                  </div>
                                  <div>{edge?.description}</div>
                                  <div className="d-flex mt-3 justify-content-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 100 125"
                                      enableBackground="new 0 0 100 100"
                                      xmlSpace="preserve"
                                      style={{ width: 20, height: 20 }}
                                      onClick={() => {
                                        setShowPopupInfo('')
                                      }}
                                    >
                                      <path d="M64.937,50l20.827-20.827c4.125-4.125,4.125-10.812,0-14.937c-4.125-4.125-10.812-4.125-14.937,0L50,35.063L29.173,14.236  c-4.125-4.125-10.812-4.125-14.937,0c-4.125,4.125-4.125,10.812,0,14.937L35.063,50L14.236,70.827  c-4.125,4.125-4.125,10.812,0,14.937c4.125,4.125,10.812,4.125,14.937,0L50,64.937l20.827,20.827c4.125,4.125,10.812,4.125,14.937,0  c4.125-4.125,4.125-10.812,0-14.937L64.937,50z" />
                                    </svg>
                                  </div>
                                </PopupInfo>
                              )}
                            {edge?.description && (
                              <InfoWrapper style={{ width: 14, height: 14 }}>
                                <Info />
                              </InfoWrapper>
                            )}
                          </RowSpan>
                        )
                      })}
                    </InnerGrid>
                  </GridCol>

                  <GridCol col={3} rows={totalMinutes}>
                    <InnerGrid rows={totalMinutes}>
                      {currentAgenda?.tuesday?.map((edge, tuesdayIndex) => {
                        const endDate = new Date(
                          `October 13, 2014 ${edge?.endtime}`
                        )
                        const startDate = new Date(
                          `October 13, 2014 ${edge?.starttime}`
                        )

                        const difference =
                          (endDate.getTime() - startDate.getTime()) /
                          1000 /
                          (60 * 60)

                        const diff = Math.abs(difference)

                        const spanLengthMinutes = diff * 60

                        let rowStart = tuesdayPointer

                        if (tuesdayPointer !== 1) {
                          rowStart = tuesdayPointer
                        }

                        const rowEnd =
                          Math.floor((tuesdayPointer += spanLengthMinutes)) - 1

                        return (
                          <RowSpan
                            rowStart={rowStart}
                            rowEnd={rowEnd}
                            onMouseEnter={() => {
                              setShowPopupInfo(`tuesday_${tuesdayIndex}`)
                            }}
                            onClick={() => {
                              if (showPopupInfo !== '') {
                                return
                              }
                              setShowPopupInfo(`tuesday_${tuesdayIndex}`)
                            }}
                            onMouseLeave={() => {
                              setShowPopupInfo('')
                            }}
                          >
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{
                                height: '100%',
                                opacity: 1,
                              }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{
                                delay: tuesdayIndex * 0.25,
                              }}
                              className="position-relative"
                            >
                              <InnerRow bg={edge?.color || 'blue'}>
                                {edge?.title}
                              </InnerRow>
                            </motion.div>
                            {showPopupInfo === `tuesday_${tuesdayIndex}` &&
                              edge?.title && (
                                <PopupInfo
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  bg={edge?.color || 'blue'}
                                  day="tuesday"
                                >
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <span
                                      style={{ fontWeight: 'bold' }}
                                      className="w-75 pr-4"
                                    >
                                      {edge?.title}
                                    </span>
                                    <span
                                      style={{ fontSize: 11 }}
                                      className="w-25"
                                    >
                                      {edge?.starttime} - {edge?.endtime}
                                    </span>
                                  </div>
                                  <div>{edge?.description}</div>
                                  <div className="d-flex mt-3 justify-content-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 100 125"
                                      enableBackground="new 0 0 100 100"
                                      xmlSpace="preserve"
                                      style={{ width: 20, height: 20 }}
                                      onClick={() => setShowPopupInfo('')}
                                    >
                                      <path d="M64.937,50l20.827-20.827c4.125-4.125,4.125-10.812,0-14.937c-4.125-4.125-10.812-4.125-14.937,0L50,35.063L29.173,14.236  c-4.125-4.125-10.812-4.125-14.937,0c-4.125,4.125-4.125,10.812,0,14.937L35.063,50L14.236,70.827  c-4.125,4.125-4.125,10.812,0,14.937c4.125,4.125,10.812,4.125,14.937,0L50,64.937l20.827,20.827c4.125,4.125,10.812,4.125,14.937,0  c4.125-4.125,4.125-10.812,0-14.937L64.937,50z" />
                                    </svg>
                                  </div>
                                </PopupInfo>
                              )}
                            {edge?.description && (
                              <InfoWrapper style={{ width: 14, height: 14 }}>
                                <Info />
                              </InfoWrapper>
                            )}
                          </RowSpan>
                        )
                      })}
                    </InnerGrid>
                  </GridCol>
                  <GridCol col={4} rows={totalMinutes}>
                    <InnerGrid rows={totalMinutes}>
                      {currentAgenda?.wednesday?.map((edge, wednesdayIndex) => {
                        const endDate = new Date(
                          `October 13, 2014 ${edge?.endtime}`
                        )
                        const startDate = new Date(
                          `October 13, 2014 ${edge?.starttime}`
                        )

                        const difference =
                          (endDate.getTime() - startDate.getTime()) /
                          1000 /
                          (60 * 60)

                        const diff = Math.abs(difference)

                        const spanLengthMinutes = diff * 60

                        let rowStart = wednesdayPointer

                        if (wednesdayPointer !== 1) {
                          rowStart = wednesdayPointer
                        }

                        const rowEnd =
                          Math.floor((wednesdayPointer += spanLengthMinutes)) -
                          1

                        return (
                          <RowSpan
                            rowStart={rowStart}
                            rowEnd={rowEnd}
                            onMouseEnter={() => {
                              setShowPopupInfo(`wednesday_${wednesdayIndex}`)
                            }}
                            onClick={() => {
                              if (showPopupInfo !== '') {
                                return
                              }
                              setShowPopupInfo(`wednesday_${wednesdayIndex}`)
                            }}
                            onMouseLeave={() => {
                              setShowPopupInfo('')
                            }}
                          >
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{
                                height: '100%',
                                opacity: 1,
                              }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{
                                delay: wednesdayIndex * 0.25,
                              }}
                            >
                              <InnerRow bg={edge?.color || 'blue'}>
                                {edge?.title}
                              </InnerRow>
                            </motion.div>
                            {showPopupInfo === `wednesday_${wednesdayIndex}` &&
                              edge?.title && (
                                <PopupInfo
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  bg={edge?.color || 'blue'}
                                  day="wednesday"
                                >
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <span
                                      style={{ fontWeight: 'bold' }}
                                      className="w-75 pr-4"
                                    >
                                      {edge?.title}
                                    </span>
                                    <span
                                      style={{ fontSize: 11 }}
                                      className="w-25"
                                    >
                                      {edge?.starttime} - {edge?.endtime}
                                    </span>
                                  </div>
                                  <div>{edge?.description}</div>
                                  <div className="d-flex mt-3 justify-content-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 100 125"
                                      enableBackground="new 0 0 100 100"
                                      xmlSpace="preserve"
                                      style={{ width: 20, height: 20 }}
                                      onClick={() => setShowPopupInfo('')}
                                    >
                                      <path d="M64.937,50l20.827-20.827c4.125-4.125,4.125-10.812,0-14.937c-4.125-4.125-10.812-4.125-14.937,0L50,35.063L29.173,14.236  c-4.125-4.125-10.812-4.125-14.937,0c-4.125,4.125-4.125,10.812,0,14.937L35.063,50L14.236,70.827  c-4.125,4.125-4.125,10.812,0,14.937c4.125,4.125,10.812,4.125,14.937,0L50,64.937l20.827,20.827c4.125,4.125,10.812,4.125,14.937,0  c4.125-4.125,4.125-10.812,0-14.937L64.937,50z" />
                                    </svg>
                                  </div>
                                </PopupInfo>
                              )}
                            {edge?.description && (
                              <InfoWrapper style={{ width: 14, height: 14 }}>
                                <Info />
                              </InfoWrapper>
                            )}
                          </RowSpan>
                        )
                      })}
                    </InnerGrid>
                  </GridCol>
                  <GridCol col={5} rows={totalMinutes}>
                    <InnerGrid rows={totalMinutes}>
                      {currentAgenda?.thursday?.map((edge, thursdayIndex) => {
                        const endDate = new Date(
                          `October 13, 2014 ${edge?.endtime}`
                        )
                        const startDate = new Date(
                          `October 13, 2014 ${edge?.starttime}`
                        )

                        const difference =
                          (endDate.getTime() - startDate.getTime()) /
                          1000 /
                          (60 * 60)

                        const diff = Math.abs(difference)

                        const spanLengthMinutes = diff * 60

                        let rowStart = thursdayPointer

                        if (thursdayPointer !== 1) {
                          rowStart = thursdayPointer
                        }

                        const rowEnd =
                          Math.floor((thursdayPointer += spanLengthMinutes)) - 1

                        return (
                          <RowSpan
                            rowStart={rowStart}
                            rowEnd={rowEnd}
                            onMouseEnter={() => {
                              setShowPopupInfo(`thursday_${thursdayIndex}`)
                            }}
                            onClick={() => {
                              if (showPopupInfo !== '') {
                                return
                              }
                              setShowPopupInfo(`thursday_${thursdayIndex}`)
                            }}
                            onMouseLeave={() => {
                              setShowPopupInfo('')
                            }}
                          >
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{
                                height: '100%',
                                opacity: 1,
                              }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{
                                delay: thursdayIndex * 0.25,
                              }}
                            >
                              <InnerRow bg={edge?.color || 'blue'}>
                                {edge?.title}
                              </InnerRow>
                            </motion.div>
                            {showPopupInfo === `thursday_${thursdayIndex}` &&
                              edge?.title && (
                                <PopupInfo
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  bg={edge?.color || 'blue'}
                                  day="thursday"
                                >
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <span
                                      style={{ fontWeight: 'bold' }}
                                      className="w-75 pr-4"
                                    >
                                      {edge?.title}
                                    </span>
                                    <span
                                      style={{ fontSize: 11 }}
                                      className="w-25"
                                    >
                                      {edge?.starttime} - {edge?.endtime}
                                    </span>
                                  </div>
                                  <div>{edge?.description}</div>
                                  <div className="d-flex mt-3 justify-content-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 100 125"
                                      enableBackground="new 0 0 100 100"
                                      xmlSpace="preserve"
                                      style={{ width: 20, height: 20 }}
                                      onClick={() => setShowPopupInfo('')}
                                    >
                                      <path d="M64.937,50l20.827-20.827c4.125-4.125,4.125-10.812,0-14.937c-4.125-4.125-10.812-4.125-14.937,0L50,35.063L29.173,14.236  c-4.125-4.125-10.812-4.125-14.937,0c-4.125,4.125-4.125,10.812,0,14.937L35.063,50L14.236,70.827  c-4.125,4.125-4.125,10.812,0,14.937c4.125,4.125,10.812,4.125,14.937,0L50,64.937l20.827,20.827c4.125,4.125,10.812,4.125,14.937,0  c4.125-4.125,4.125-10.812,0-14.937L64.937,50z" />
                                    </svg>
                                  </div>
                                </PopupInfo>
                              )}
                            {edge?.description && (
                              <InfoWrapper style={{ width: 14, height: 14 }}>
                                <Info />
                              </InfoWrapper>
                            )}
                          </RowSpan>
                        )
                      })}
                    </InnerGrid>
                  </GridCol>
                  <GridCol col={6} rows={totalMinutes}>
                    <InnerGrid rows={totalMinutes}>
                      {currentAgenda?.friday?.map((edge, fridayIndex) => {
                        const endDate = new Date(
                          `October 13, 2014 ${edge?.endtime}`
                        )
                        const startDate = new Date(
                          `October 13, 2014 ${edge?.starttime}`
                        )

                        const difference =
                          (endDate.getTime() - startDate.getTime()) /
                          1000 /
                          (60 * 60)

                        const diff = Math.abs(difference)

                        const spanLengthMinutes = diff * 60

                        let rowStart = fridayPointer

                        if (fridayPointer !== 1) {
                          rowStart = fridayPointer
                        }

                        const rowEnd =
                          Math.floor((fridayPointer += spanLengthMinutes)) - 1

                        return (
                          <RowSpan
                            rowStart={rowStart}
                            rowEnd={rowEnd}
                            onMouseEnter={() => {
                              setShowPopupInfo(`friday_${fridayIndex}`)
                            }}
                            onClick={() => {
                              if (showPopupInfo !== '') {
                                return
                              }
                              setShowPopupInfo(`friday_${fridayIndex}`)
                            }}
                            onMouseLeave={() => {
                              setShowPopupInfo('')
                            }}
                          >
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{
                                height: '100%',
                                opacity: 1,
                              }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{
                                delay: fridayIndex * 0.25,
                              }}
                            >
                              <InnerRow bg={edge?.color || 'blue'}>
                                {edge?.title}
                              </InnerRow>
                            </motion.div>
                            {showPopupInfo === `friday_${fridayIndex}` &&
                              edge?.title && (
                                <PopupInfo
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  bg={edge?.color || 'blue'}
                                  day="friday"
                                >
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <span
                                      style={{ fontWeight: 'bold' }}
                                      className="w-75 pr-4"
                                    >
                                      {edge?.title}
                                    </span>
                                    <span
                                      style={{ fontSize: 11 }}
                                      className="w-25"
                                    >
                                      {edge?.starttime} - {edge?.endtime}
                                    </span>
                                  </div>
                                  <div>{edge?.description}</div>
                                  <div className="d-flex mt-3 justify-content-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 100 125"
                                      enableBackground="new 0 0 100 100"
                                      xmlSpace="preserve"
                                      style={{ width: 20, height: 20 }}
                                      onClick={() => setShowPopupInfo('')}
                                    >
                                      <path d="M64.937,50l20.827-20.827c4.125-4.125,4.125-10.812,0-14.937c-4.125-4.125-10.812-4.125-14.937,0L50,35.063L29.173,14.236  c-4.125-4.125-10.812-4.125-14.937,0c-4.125,4.125-4.125,10.812,0,14.937L35.063,50L14.236,70.827  c-4.125,4.125-4.125,10.812,0,14.937c4.125,4.125,10.812,4.125,14.937,0L50,64.937l20.827,20.827c4.125,4.125,10.812,4.125,14.937,0  c4.125-4.125,4.125-10.812,0-14.937L64.937,50z" />
                                    </svg>
                                  </div>
                                </PopupInfo>
                              )}
                            {edge?.description && (
                              <InfoWrapper style={{ width: 14, height: 14 }}>
                                <Info />
                              </InfoWrapper>
                            )}
                          </RowSpan>
                        )
                      })}
                    </InnerGrid>
                  </GridCol>
                </Grid>
              </div>
            </GridWrapper>
          </>
        </div>
      </Wrapper>
    </section>
  )
}

export default Calendar
