import React from 'react'

export default function Info() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.11 179.11">
      <g fill="currentColor">
        <path d="M89.56 53.45c7.16 0 12.97-5.81 12.97-12.97s-5.81-12.97-12.97-12.97-12.97 5.81-12.97 12.97 5.81 12.97 12.97 12.97z" />
        <path d="M98.2 112.38L98.2 95.08 98.2 77.79 98.2 60.5 80.91 60.5 72.26 60.5 72.26 77.79 80.91 77.79 80.91 95.08 80.91 112.38 80.91 129.67 80.91 146.96 98.2 146.96 106.85 146.96 106.85 129.67 98.2 129.67 98.2 112.38z" />
        <path d="M89.56 0C40.17 0 0 40.18 0 89.56s40.17 89.56 89.56 89.56 89.56-40.18 89.56-89.56S138.94 0 89.56 0zm0 162.46c-40.2 0-72.91-32.71-72.91-72.91s32.71-72.9 72.91-72.9 72.91 32.71 72.91 72.91-32.71 72.91-72.91 72.91z" />
      </g>
    </svg>
  )
}
